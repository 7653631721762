import classNames from 'classnames'
import { Icon, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { Link } from 'components/Link/Link'
import { useTranslation } from 'hooks/useTranslation'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useWindowScroll } from 'react-use'
import { Routes } from 'services/router/Routes'
import { setLocaleCookie } from 'utils/cookieSetter'

import styles from './HeaderDesktop.module.scss'
import { HeaderDesktopLink } from './HeaderDesktopLink'
import { HeaderLink } from './HeaderLink'
import { ToggleHeaderLink } from './ToggleHeaderLink'

type HeaderDesktopProps = {
  className?: string
  placeholder?: boolean
  transparent?: boolean
  userName: string | null
}

export const HeaderDesktop: FC<HeaderDesktopProps> = ({ className, placeholder = true, transparent = false, userName }) => {
  const { y } = useWindowScroll()
  const { t } = useTranslation()
  const { locale, asPath } = useRouter()

  const separatedFromTop = y > 0

  const isTransparent = transparent && !separatedFromTop

  const menuLinks = [
    {
      icon: IconName.HOME5,
      title: t('header:menu.my-house'),
      route: Routes.myAccount(),
      useSSRRouting: true,
      disabled: false,
    },
    /*
    {
      icon: IconName.ACCOUNT_CIRCLE,
      title: t('header:menu.my-data'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.FILE_LIST,
      title: t('header:menu.my-contracts'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.HEART,
      title: t('header:menu.my-favorites'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.TOOLS,
      title: t('header:menu.maintenance'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.EQUALIZER,
      title: t('header:menu.settings'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    
    */
    {
      icon: IconName.LOGOUT,
      title: t('header:menu.logout'),
      route: Routes.logout(),
      useSSRRouting: true,
      disabled: false,
    },
  ]

  const submenuLinks = {
    about: [{ route: Routes.blog(), title: t('header:blog'), external: true }],
  }

  const generalLinks = [
    { route: Routes.howItWorks(), title: t('header:how-it-works') },
    { route: Routes.properties({}), title: t('header:properties') },
    { route: Routes.about(), title: t('header:about-us'), hasSubmenu: true, subMenuList: submenuLinks.about },
    { route: Routes.faq(), title: t('header:frequent-asked-questions') },
    { route: Routes.agency(), title: t('header:agents') },
  ]

  type GenerateLinkProps = {
    route: string
    title: string
    hasSubmenu?: boolean
    subMenuList?: Array<{ route: string; title: string; external: boolean }>
  }

  const GenerateLink: FC<GenerateLinkProps> = ({ route, title, hasSubmenu, subMenuList }) => {
    if (hasSubmenu) {
      return (
        <div className={styles.localeWrapper}>
          <HeaderDesktopLink className={styles.localeButton} transparent={isTransparent} route={route}>
            {title}
          </HeaderDesktopLink>
          <div className={classNames(styles.localeOptionsContainer, styles.sublistContainer)}>
            {subMenuList &&
              subMenuList.map((subitem) => (
                <Link key={subitem.title} route={subitem.route} className={styles.localeOption} newTarget={subitem.external}>
                  {subitem.title}
                </Link>
              ))}
          </div>
        </div>
      )
    }
    return (
      <HeaderDesktopLink key={title} transparent={isTransparent} route={route}>
        {title}
      </HeaderDesktopLink>
    )
  }

  return (
    <header
      className={classNames(className, styles.headerContainer, separatedFromTop && styles.separatedFromTop, isTransparent && styles.transparent)}
    >
      <div className={`${styles.container} ${styles.header}`}>
        <Link route={Routes.landing()} ariaLabel={t('header:index')} className={styles.logoLink}>
          <img height={24} width={104} className={classNames(styles.logo, styles.logoColor)} src="/images/logo.svg" alt="libeen" />
          <img height={24} width={104} className={classNames(styles.logo, styles.logoWhite)} src="/images/logo-white.svg" alt="libeen" />
        </Link>
        <div className={styles.links}>
          {generalLinks.map(({ route, title, hasSubmenu, subMenuList }) => (
            <GenerateLink key={route} route={route} title={title} hasSubmenu={hasSubmenu} subMenuList={subMenuList} />
          ))}
          {!userName && (
            <div id="find-home-desktop-button">
              <HeaderDesktopLink transparent={isTransparent} isImportant route={Routes.wizardCities()}>
                {t('header:find-home')}
              </HeaderDesktopLink>
            </div>
          )}
          {!userName && (
            <HeaderDesktopLink className={styles.loginButton} transparent={isTransparent} route={Routes.userLogin()} hasIcon>
              <Icon size={16} name={IconName.USER} className={styles.icon} /> <span>{t('header:login')}</span>
            </HeaderDesktopLink>
          )}
          {userName && (
            <ToggleHeaderLink title={t('header:hello-customer')} transparent={isTransparent}>
              {menuLinks.map(({ icon, title, route, disabled, useSSRRouting }) => (
                <HeaderLink key={title} path={route} title={title} iconName={icon} useSSRRouting={useSSRRouting} disabled={disabled} />
              ))}
            </ToggleHeaderLink>
          )}
          <div className={styles.localeWrapper}>
            <HeaderDesktopLink className={styles.localeButton} transparent={isTransparent} route={asPath}>
              <Icon size={16} name={IconName.GLOBAL} className={styles.icon} /> {locale}{' '}
              <Icon size={16} name={IconName.DOWN_ARROW} type={IconType.FILL} className={`${styles.icon} ${styles.iconToggle}`} />
            </HeaderDesktopLink>
            <div className={styles.localeOptionsContainer}>
              <Link
                route={asPath}
                onClick={() => setLocaleCookie('es')}
                locale="es"
                className={styles.localeOption + (locale === 'es' ? ` ${styles.localeOptionSelected}` : '')}
              >
                {t('header:language.es')}
              </Link>
              <Link
                route={asPath}
                onClick={() => setLocaleCookie('en')}
                locale="en"
                className={styles.localeOption + (locale === 'en' ? ` ${styles.localeOptionSelected}` : '')}
              >
                {t('header:language.en')}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {placeholder && <div className={styles.placeholder} />}
    </header>
  )
}
